import styled from "styled-components";
import { ReactComponent as Background } from "../../assets/images/banner.svg";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    141.72deg,
    #ffc700 15.06%,
    #ff8a00 48.49%,
    #ff2e00 73.94%
  );
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: space-between; */
`;

export const ContatBtn = styled.button`
  padding: 1rem 2rem;
  background: linear-gradient(
    141.72deg,
    #ffc700 15.06%,
    #ff8a00 48.49%,
    #ff2e00 73.94%
  );
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
  margin-top: 4rem;
  border: 1px solid #000;
  border-radius: 10pc;
`;

export const WrapperBanner = styled.div`
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
`;

export const Banner = styled(Background)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
