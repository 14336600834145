import React from "react";
import { Banner, Container, ContatBtn, WrapperBanner } from "./styles";
// import Header from "../../Components/Header";
// import Investor from "../../Components/Investor";
// import Footer from "../../Components/Footer";
// import ListMenu from "../../Components/ListMenu";

const Home: React.FC = () => {
  return (
    <Container>
      <h1>PÁGINA FORA DO AR POR FALTA DE PAGAMENTO.</h1>
      <h1>
        POR FAVOR, ENTRE EM CONTATO COM O DESENVOLVEDOR PARA RESOLUÇÃO DO
        PROBLEMA.
      </h1>

      <ContatBtn
        onClick={() =>
          window.open(
            "https://wa.me/5581985170293?text=Oi%20Rodrigo%2C%20quero%20resolver%20o%20pagamento%20do%20site%20ECOWATTS%2C%20como%20podemos%20fazer%20%3F"
          )
        }
      >
        Entre em contato
      </ContatBtn>
      {/* <Header />
      <WrapperBanner>
        <Banner />
      </WrapperBanner> */}
      {/* <ListMenu /> */}
      {/* <Investor />
      <Footer /> */}
    </Container>
  );
};

export default Home;
