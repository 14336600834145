import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
   
 * {
     margin: 0;
     padding: 0;
     outline: 0;
     box-sizing: border-box;
 }
 *:focus {
     outline:0;
 }

html, body, #root{     
    height:100%;
   
}  

body {
    -webkit-font-smoothing: antialiased;
}

body, input, button {
    font: 14px 'Roboto', sans-serif;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

button {
    cursor: pointer;
}

@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: 'Greycliff CF Bold Oblique';
  src: url('../assets/fonts/GreycliffCF-BoldOblique.otf');
}

/* add custom scrollbar in global project 👇🏼👇🏼👻 */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #1d1e1f;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(178.8deg, #ffae00 47.41%, #ff6500 65.18%, #ff2e00 90.84%);
  border-radius: 20px;
}

/* Cor do Texto selecionado 👇🏼👇🏼👻 */
::-moz-selection {
  color: black;
  background: #ffff;
}

::selection {
  color: black;
  background: #ffff;
}
`;
